label {
    font-size: 18px;
}

.btn {
    font-size: 12px;
}

small {
    font-size: 12px !important;
}

.beta {
    font-size: 0.8rem;
}
